<backoffice-header-configuration
  title="@@backoffice.contacts.title"
  subTitle="@@backoffice.contacts.subtitle"
  [icon]="BACKOFFICE_ICONS.contact"
>
  <ng-template #actions>
    <button data-test="createContactBtn" class="btn" (click)="onOpenContactEditor()">
      <smallstack-i18n data="@@actions.create" />
    </button>
  </ng-template>
</backoffice-header-configuration>

<div fullwidth>
  <div class="m-2">
    <smallstack-widget-tree [widget]="searchContainer" [widgetRendererOptions]="{ editMode: false }" />
  </div>
  <smallstack-list-container [store]="contactStore" [pageSize]="100">
    <ng-template #content>
      <table
        mat-table
        style="width: 100%"
        matSort
        [dataSource]="contactStore.currentPage$"
        [smallstackMatSortStore]="contactStore"
      >
        <ng-container matColumnDef="avatar">
          <th *matHeaderCellDef mat-header-cell> </th>
          <td *matCellDef="let contact" mat-cell style="vertical-align: middle">
            <smallstack-avatar-image
              panelClass="rounded tiny"
              label="{{ contact?.firstName ? contact.firstName[0] : '' }}{{
                contact?.lastName ? contact.lastName[0] : ''
              }}"
              [imageSrc]="contact.avatarUrl"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="salutation">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@user.salutation" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.salutation || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@user.title" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.title || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@components.crm.contacts.firstname" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.firstName || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@components.crm.contacts.lastname" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.lastName || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="company">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@nouns.company" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.company || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="companyPhone">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@nouns.companyPhone" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.companyPhone || "-" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@components.crm.contacts.email" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.email || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@components.crm.contacts.phone" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.phone || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <smallstack-i18n data="@@components.crm.contacts.mobile" />
          </th>
          <td *matCellDef="let contact" mat-cell>
            {{ contact?.mobile || "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="no-print">
            <smallstack-i18n data="@@nouns.actions" />
          </th>
          <td *matCellDef="let contact" mat-cell class="no-print" style="white-space: nowrap">
            <button class="btn btn-action btn-primary" (click)="onOpenContactDetails(contact)">
              <i class="fa-regular fa-square-list"></i>
            </button>
            <button
              class="btn btn-action btn-primary"
              data-test="edit-contact-btn"
              (click)="onOpenContactEditor(contact)"
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
            <button class="btn btn-action btn-primary" (click)="openCalendarEntries(contact)">
              <i class="fa-regular fa-calendar"></i>
            </button>
            <button class="btn btn-action btn-primary" [routerLink]="['.']" [queryParams]="{ openTodoId: contact.id }">
              <i class="fa-regular fa-list-check"></i>
            </button>
            <button class="btn btn-action btn-error" [loadingFn]="onDeleteContact(contact.id)">
              <i class="fa-regular fa-trash-can"></i>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="COLUMNS" mat-header-row></tr>
        <tr *matRowDef="let row; columns: COLUMNS" mat-row></tr>
      </table>
    </ng-template>
  </smallstack-list-container>
</div>
